.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-container {
  text-align: center;
  width: 100vw;
  background-color: #3c3a3a;
  padding-bottom: -30px;
  color: white;
}

.skills-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.skill-box {
  height: 50vh;
  background: white;
  box-shadow: 2px 2px 3px 2px lightgrey;
  width: 30%;
  text-align: center;
}

.skill-box > h4 {
  background: black;
  color: white;
  padding: 10px;
}
.skill-box > h2 {
  
}

.skill-info-container {
  padding: 20px;
}

.snowball-button-link {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 20px;
  font-weight: bold;
  outline-color: none;
}
